<template>
    <div class="wizard-panel">
        <panel>
            <panel-top-bar variant="primary-dark" with-logo />
            <div class="panel-content">
                <h1 class="display-2 text-primary-dark">New Poster</h1>
                <h6 class="text-primary-dark">Create an account </h6>
                <div class="mt-5">
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Email Address" />
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Password" />
                    </div>
                    <div class="form-group">
                        <button type="submit" class="btn btn-primary btn-block btn-rounded">
                            Create Account
                        </button>
                    </div>
                    <div class="row mt-4">
                        <div class="col-6">
                            <social-button type="facebook" label="Facebook" icon="facebook-f" />
                        </div>
                        <div class="col-6">
                            <social-button type="google" label="Google" icon="google" />
                        </div>
                    </div>
                    <div class="mt-4">
                        <h6 class="mb-0 font-weight-normal">Already have an account? <a href="#">Login</a></h6>
                    </div>
                </div>
            </div>
            <div class="panel-footer panel-footer-box">
                <button type="button" class="btn btn-default" @click="changeStep(1)">Step 1</button>
                <button type="button" class="btn btn-primary" @click="changeStep(2)">Step 2</button>
            </div>
        </panel>
        <panel v-if="step === 1">
            <panel-top-bar variant="primary" closable @close="changeStep(0)" />
            <div class="panel-content">
                <h1 class="display-2 text-primary-dark">Verify Account</h1>
                <h6 class="text-primary-dark">We have sent a verification code to your email.</h6>
                <div class="row mt-5">
                    <div class="col">
                        <div class="form-group">
                            <input type="text" class="form-control" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <input type="text" class="form-control" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <input type="text" class="form-control" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <input type="text" class="form-control" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <input type="text" class="form-control" />
                        </div>
                    </div>
                </div>
                <div class="form-group mt-3">
                    <button type="button" class="btn btn-primary btn-block btn-rounded">Verify</button>
                </div>
                <div class="text-right">
                    <p>Didn’t get the One Time Pin? <a href="#">Resend</a></p>
                </div>
            </div>
        </panel>
        <panel full v-if="step === 2">
            <panel-top-bar variant="primary-dark" closable @close="changeStep(0)" />
            <div class="panel-content p-0 d-flex flex-column">
                <div class="bg-primary p-4 text-white">
                    <div class="d-flex align-items-center justify-content-between mb-2">
                        <div>Progress</div>
                        <div>10%</div>
                    </div>
                    <b-progress max="100" variant="success" height="10px">
                        <b-progress-bar value="10" class="rounded"></b-progress-bar>
                    </b-progress>
                </div>
                <iframe width="100%" height="100%" frameborder="0" class="flex-grow-1" style="border:0" src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAuzRopvvFK6QWVPYnrMfadYxChTa-glEQ&q=Colorado+Springs"></iframe>
            </div>
        </panel>
    </div>
</template>

<script>
    import Panel from "../../components/Panel";
    import PanelTopBar from "../../components/PanelTopBar";
    import SocialButton from "../../components/SocialButton";

    export default {
        name: 'WizardLayout',
        components: {
            SocialButton,
            PanelTopBar,
            Panel
        },
        data() {
            return {
                step: 0,
            }
        },
        methods: {
            changeStep(step) {
                this.step = step;
            }
        }
    }
</script>
